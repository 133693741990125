import(/* webpackMode: "eager" */ "/workspace/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/workspace/components/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/workspace/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
